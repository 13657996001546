import React from 'react';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import { myStyles } from './homestyles';
import Container from '@material-ui/core/Container';
import './TechnologyHeader.scss';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const TechnologyHeader = ({
  headerSectionTitle,
  headerSectionDescription,
  heroImage,
  images,
  heroImageDescription
}) => {
  const classes = myStyles(images);
  return (
    <>
      <Grid className={clsx('technology-content-cls', classes['technology-content-cls'])}>
        <Container maxWidth="xl" className="zero-padding-div ">
          <Grid container className={clsx('header-container', classes['bg-header-container'])}>
            <Grid item xs={6} sm={6} md={5} lg={5} xl={5} className="header-left-text">
              <h1 className="title">{headerSectionTitle}</h1>
              {headerSectionDescription?.raw &&
                documentToReactComponents(JSON.parse(headerSectionDescription?.raw))}
            </Grid>
            <Grid item xs={6} sm={6} md={7} lg={7} xl={7}>
              <div className="header-image">
                <img src={`${heroImage}?fm=webp&q=100`} alt={heroImageDescription} width="100%" />
              </div>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </>
  );
};

export default TechnologyHeader;
