import { makeStyles } from '@material-ui/core';

export const myStyles = makeStyles(() => ({
  'home-tech-slider-cls': (data) => ({
    background: `url(${data?.gridBrightBlueBackgroundImage?.file?.url}?fm=webp&q=100)`,
    '&::before': {
      background: `url(${data?.donutSliderBackgroundImage?.file?.url}?fm=webp&q=100) no-repeat`,
    },
  }),
  'home-tech-dotmat-cls': (data) => ({
    '&::before': {
      background: `url(${data?.headerDonutImage?.file?.url}) no-repeat`,
    },
    '&::after': {
      background: `url(${data?.headerDotMatrixImage?.file?.url}) no-repeat`,
    },
  }),
    'bg-header-container': (data) => ({
    '&::after': {
      background: `url(${data?.dotMatrixBackgroundImage?.file?.url}?fm=webp&q=100) no-repeat`,
    },
  }),
  'technology-content-cls': (data) => ({
    '&::after': {
      background: `url(${data?.capsulesBackgroundImage?.file?.url}?fm=webp&q=100) no-repeat`,
    },
  }),
}));
