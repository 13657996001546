import React, { useState } from 'react';
import './technology-landing.scss';
import TechnologyHeader from './TechnologyHeader';
import TechnologyOurProudExecution from './TechnologyOurProudExecution';
import TechnologyOurInnovationTeam from './TechnologyOurInnovationTeam';
import LetsTalk from '../LetsTalk';
import RequestFormPopup from '../RequestFormPopup';
import HomeQuoteSlider from '../HomeQuoteSlider';
import { myStyles } from './style';
import clsx from 'clsx';

const TechnologyLanding = ({ data, images, basePath, location }) => {
  const classes = myStyles(data);  
  return (
    <div className="technology-main">
      <TechnologyHeader data={data} images={images}/>
      {data?.customerTestimonials && <HomeQuoteSlider data={data?.customerTestimonials} backgroundImg={images} autoRotateEnabled={data?.customerTestimonialsAutoRotate}/>}
      <div className={clsx("donuts-left", classes["bg-left-donut"])}>
        <TechnologyOurProudExecution
          title={data?.ourProudExecutionTitle?.raw}
          img={data?.proudExecutionImage}
          tabData={data?.proudExecutionNavigationMenu}
          data={data}
          basePath={basePath}
          location={location}
          
        />
        <div className={clsx("capsules-bg", classes["bg-right-capsules"])}>
          <TechnologyOurInnovationTeam
            ArrowImage={data?.rightArrowImage}
            ancillaryTechnologySectionRows={data?.ancillaryTechnologySectionRows}
            SectionTitle={data?.ancillaryTechnologySectionTitle}
            data={data}
            bgImages={images}
          />
        </div>
      </div>
      <LetsTalk
        title={data?.technologyPageLetsTalkSectionTitle}
        image={data?.technologyPageLetsTalkBackgroundImage}
        LetsTalkButton={data?.letsTalkButtonLink}
        LetsTalktitle="technologyPageLetsTalkSectionTitle"
        LetsTalkBackgroundImage="technologyPageLetsTalkBackgroundImage"
        ButtonLabel="buttonLabel"
        basePath={basePath}
      />
      <RequestFormPopup data={data?.pardotForm} images={images}/>
    </div>
  );
};

export default TechnologyLanding;
