import React from 'react';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import { myStyles } from './styles';
import HeaderComponent from '../../HeaderComponent/Index';
import HeaderIntroComponent from '../../HeaderIntroComponent/Index';
import Container from '@material-ui/core/Container';
import HomeHeader from './HomeHeader';
import InternalHeader from './InternalHeader';

const TechnologyHeader = ({ data, images }) => {
  const classes = myStyles(data);
  const {
    circularSectionTitle,
    circularSectionDescription,
    heroImage,
    technologyPageIntroSection,
    headerText,
    shortDescription,
    headerType,
  } = data;
  const renderHeader = (headerType) => {
    switch (headerType) {
      case 'Home':
        return (
          <div className="landing-hero-img-div">
            <HomeHeader
              circularSectionTitle={circularSectionTitle}
              circularSectionDescription={circularSectionDescription}
              heroImage={data?.homeCircularHeroImage?.file?.url}
              heroImageAlt={data?.homeCircularHeroImage?.description}
              images={images}
            />
          </div>
        );
      case 'Internal':
        return (
          <div className="landing-hero-img-div">
            <InternalHeader
              headerSectionTitle={circularSectionTitle}
              headerSectionDescription={circularSectionDescription}
              heroImage={data?.internalPageHeroImage?.file?.url}
              heroImageDescription={data?.internalPageHeroImage?.description}
              images={images}
            />
          </div>
        );
      default:
        return (
          <div className={clsx('landing-hero-img-div', classes['landing-hero-img-div'])}>
            <HeaderComponent
              heroImage={heroImage}
              classes={classes}
              circularSectionTitle={circularSectionTitle}
              circularSectionDescription={circularSectionDescription}
            />
          </div>
        );
    }
  };
  return (
    <>
      {renderHeader(headerType)}
      <Container maxWidth="xl" className="zero-padding-div ">
        <Grid
          container
          className={clsx('txt-with-video-container', classes['txt-with-video-container'])}
        >
          <HeaderIntroComponent
            storyhistorySection={technologyPageIntroSection}
            aboutUsStorySectionTopic={headerText}
            aboutUsStorySectionDescription={shortDescription}
            classes={classes}
          />
        </Grid>
      </Container>
    </>
  );
};

export default TechnologyHeader;
