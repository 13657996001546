import React, { useRef, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { Link } from 'gatsby';
import { Button, Box } from '@material-ui/core';
import { myStyles } from './style.js';
import clsx from 'clsx';
import './OurProudExecution.scss';
import Container from '@material-ui/core/Container';
import GlobalServiceContentServices from '../../GlobalServiceContent/GlobalServiceContentServices'


const TechnologyOurProudExecution = ({ title, img, tabData, data, basePath, location }) => {
  const [value, setValue] = useState(1);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const rootEl = useRef(null);
  const removeAriaLabel = () => {
    setTimeout(() => {
      let ourKeyNodes = rootEl?.current?.querySelectorAll('.tab-panel');
      if (ourKeyNodes) {
        ourKeyNodes.forEach((ourKeyNode) => {
          ourKeyNode.removeAttribute('aria-labelledby');
        });
      }

    }, 0);
  };
  useEffect(() => {
    removeAriaLabel();
  }, []);

  const classes = myStyles(data);

  return (
    <>
      {data?.proudExecutionTabOrAccordion ? (
        <div ref={rootEl}>
          <Container maxWidth="xl" className='technology-our-key'>
            <Grid container>
              <Grid item container xl={6} lg={6} md={6} sm={7} xs={7}>
                <h2 className="heading" locId="title">
                  <img src={img?.file?.url + '?fm=webp&q=100'} alt={img?.description} locId="plusImage" className="plus-img" />
                  {title &&
                    documentToReactComponents(JSON.parse(title))}
                </h2>
              </Grid>
              <Grid
                item
                container
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="txt-with-blue-section"
              >
                <Grid
                  item
                  container
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  spacing={2}
                  className="rich-text-div"
                >
                  <Box sx={{ width: '100%' }}>
                    <TabContext value={value}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                          value={value}
                          onChange={handleChange}
                          variant="scrollable"
                          scrollButtons={true}
                          aria-label="scrollable prevent tabs example"
                          className="ourkey-tabs"
                          locId="tabTitle"
                        >
                          {tabData.map((tabLabel, index) => (
                            <Tab
                              label={tabLabel.tabTitle}
                              locId={`tabLabel${index}`}
                              value={index + 1}
                              tabIndex="0"
                            />
                          ))}
                        </Tabs>
                      </Box>
                      {tabData.map((tabContent, index) => {
                        return (

                          <TabPanel value={index + 1} className="tab-panel">
                            <Grid
                              container
                              item
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              className="tab-details"
                            >
                              <Grid
                                container
                                item
                                xl={7}
                                lg={7}
                                md={11}
                                sm={12}
                                xs={12}
                                className="ourkey-tabcontext"
                              >
                                <Grid
                                  item
                                  xl={12}
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                  className="keytab-content"
                                  locId="description"
                                >
                                  <p className='blue-box-link'>
                                    {tabContent?.description?.raw &&
                                      documentToReactComponents(JSON.parse(tabContent?.description?.raw))}
                                  </p>

                                  {(tabContent?.ctaButtonLink || tabContent?.ctaButtonLink?.label) &&
                                    (tabContent?.ctaButtonLink?.link?.url ? (
                                      <Link
                                        to={tabContent?.ctaButtonLink?.link?.url ? tabContent?.ctaButtonLink?.link?.url : '#'}
                                        tabindex="0"
                                      >
                                        <Button className="button-tab" tabIndex="-1" locId="label">
                                          {tabContent?.ctaButtonLink?.label}
                                        </Button>
                                      </Link>
                                    ) : (
                                      <Link
                                        to={
                                          tabContent?.ctaButtonLink?.link?.slug
                                            ? `/${basePath}/${tabContent?.ctaButtonLink?.link?.slug}`
                                            : '#'
                                        }
                                        tabindex="0"
                                      >
                                        <Button
                                          className="button-tab"
                                          tabIndex="-1"
                                          locId="label"
                                        >
                                          {tabContent?.ctaButtonLink?.label}
                                        </Button>
                                      </Link>
                                    ))}
                                </Grid>
                                {tabContent?.pdfImage?.file?.url &&
                                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <div className='ourkey-imgtab'>
                                      <Link
                                        href={tabContent?.uploadPdf?.file?.url}
                                        target="_blank"
                                        locId="download"
                                        tabIndex="0"
                                      >
                                        <img
                                          src={`${tabContent?.pdfImage?.file?.url}?fm=webp&q=100`}
                                          alt={tabContent?.pdfImage?.description}
                                          className="ourkey-img"
                                          locId="pdfImage"
                                        />
                                        <span className="img-subtitle">{tabContent?.download}</span>
                                      </Link>
                                    </div>
                                  </Grid>
                                }
                              </Grid>
                              <Grid item xl={5} lg={5} md={12} sm={12} xs={12} className="right-author-div" locId="testimonialDetails">
                                <img src={tabContent?.image?.file?.url + '?fm=webp&q=100'} alt={tabContent?.image?.description} />
                                {tabContent?.testimonialDescription?.raw &&
                                  documentToReactComponents(
                                    JSON.parse(tabContent?.testimonialDescription?.raw)
                                  )}
                                <p className="author-name" locId="authorName">
                                  {tabContent?.authorName}
                                </p>
                                <p className="designation" locId="designation">{tabContent?.designation}</p>
                              </Grid>
                            </Grid>
                          </TabPanel>
                        )
                      })}
                    </TabContext>
                  </Box>
                </Grid>
              </Grid>
              <Grid
                item
                container
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className={clsx('capsules-bg', classes['capsule-img'])}
              ></Grid>
            </Grid>
          </Container>
        </div>) : (
        data?.proudExecutionAccordionMenu && <GlobalServiceContentServices
          serviceImage={data?.proudExecutionImage}
          serviceTitle={documentToReactComponents(JSON.parse(data?.ourProudExecutionTitle.raw))}
          ourServices={data?.proudExecutionAccordionMenu}
          location={location}
        />


      )
      }
    </>
  );
};

export default TechnologyOurProudExecution;
