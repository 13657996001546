import React from 'react';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import { myStyles } from './homestyles';
import Container from '@material-ui/core/Container';
import './TechnologyHeader.scss';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

export default function TechnologyHeader({
  circularSectionTitle,
  circularSectionDescription,
  heroImage,
  heroImageAlt,
  images,
}) {
  const classes = myStyles(images);
  return (
    <div className={clsx('home-dotmatrix', classes['home-tech-dotmat-cls'])}>
      <Grid className={clsx('home-slider-cls', classes['home-tech-slider-cls'])} locId="homeSlider">
        <Container maxWidth="xl" className="slider-padding-none">
          <Grid container className="slider-container">
            <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
              <div className="slider-image">
                <img
                  src={`${heroImage}?fm=webp&q=100`}
                  alt={heroImageAlt}
                  locId="heroImage"
                  width="100%"
                />
              </div>
            </Grid>

            <Grid item xs={12} sm={10} md={5} lg={5} xl={5}>
              <Grid className="hero-image-content">
                <h1 className="title" locId="title">
                  {circularSectionTitle}
                </h1>
                {circularSectionDescription?.raw &&
                  documentToReactComponents(JSON.parse(circularSectionDescription?.raw))}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </div>
  );
}
