import React from 'react';
import './OurInnovationTeam.scss';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import OurInnovationTeamLeftAlign from './OurInnovationTeamLeftAlign';
import OurInnovationTeamRightAlign from './OurInnovationTeamRightAlign';
import HomeQuoteSliderRight from '../../HomeQuoteSliderRight';
import { myStyles } from './style';


export default function TechnologyOurInnovationTeam({
  ArrowImage,
  SectionTitle,
  ancillaryTechnologySectionRows,
  data,
  bgImages
}) {
  const classes = myStyles(data);
  return (
    <>
      <Container maxWidth="xl" className='our-innovation-team'>
        <Grid container item xs={8} sm={8} md={8} lg={7} xl={7} className='title-section'>
          <h2 className="title" locId="ancillaryTechnologySectionTitle">
            <img
              src={ArrowImage?.file?.url+'?fm=webp&q=100'}
              alt={ArrowImage?.description}
              className="arrow-img"
              locId="rightArrowImage"
            />
            {SectionTitle}
          </h2>
        </Grid>

        {ancillaryTechnologySectionRows.map((item, index) => {
          return (
            <>
              {index % 2 === 0 && (
                <OurInnovationTeamLeftAlign
                  name={item?.name}
                  description={item?.description?.raw}
                  image={item?.image}
                />
              )}
              {(index===1 && data?.customerTestimonial) && (<HomeQuoteSliderRight data={data?.customerTestimonial} backgroundImg={bgImages} autoRotateEnabled={data?.customerTestimonialAutoRotate}/>)}
              {index % 2 != 0 && (
                <OurInnovationTeamRightAlign
                  name={item?.name}
                  description={item?.description?.raw}
                  image={item?.image}
                  data={data}
                />
              )}
            </>
          );
        })}
      </Container>
    </>
  );
}
