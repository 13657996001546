import { makeStyles } from '@material-ui/core';

export const myStyles = makeStyles(() => ({
  'landing-hero-img-div': (data) => ({
    '&::after': {
      background: `url(${data.dotMatrixBackgroundImage.file.url}?fm=webp&q=100) no-repeat`,
    },
  }),
  'txt-with-video-container-cap': (data) => ({
    '&::before': {
      background: `url(${data.capsulesLeftImage.file.url}?fm=webp&q=100)`,
    },
  }),
  'blue-round-div': (data) => ({
    background: `url(${data?.circularSectionBackgroundImage?.file?.url}?fm=webp&q=100)`,
  }),
  'image-box':(data)=>({
    '&::after':{
        background: `url(${data.dotMatrixBackgroundImage.file.url}?fm=webp&q=100)`,
    }
  }),
  'bg-why-cartus-hero':(data)=>({
    background: `url(${data.heroImage?.file?.url}?fm=webp&q=100) no-repeat`,
    '&::after':{
        background: `url(${data.dotMatrixBackgroundImage.file.url}?fm=webp&q=100) no-repeat`,
    }
  })
}));
