import { makeStyles } from '@material-ui/core';

export const myStyles = makeStyles(() => ({
  'bg-left-donut': (data) => ({
    background: `url(${data?.leftDonutBackgroundImage?.file.url}?fm=webp&q=100) no-repeat`,
  }),
  'bg-right-capsules': (data) => ({
    background: `url(${data?.capsulesBackgroundImage?.file?.url}?fm=webp&q=100) no-repeat`,
  })
}));
