import React from "react";
import Grid from '@material-ui/core/Grid';
import './OurInnovationTeamLeftAlign.scss';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const OurInnovationTeamLeftAlign = ({ name, description, image }) => {
    return (
        <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className='left-align'>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <h3 locId="name">{name}</h3>
            </Grid>
            <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className='row-section'>
                <Grid item xl={6} lg={7} md={12} sm={12} xs={12}>
                    <p className="description" locId="description">
                        {documentToReactComponents(JSON.parse(description))}</p>
                </Grid>
                <Grid item xl={6} lg={5} md={12} sm={12} xs={12} >
                    <div className='img-box' locId="image">
                        <img src={image?.file?.url+'?fm=webp&q=100'} alt={image?.description} />
                    </div>
                </Grid>
            </Grid>
        </Grid>
    );
};
export default OurInnovationTeamLeftAlign;