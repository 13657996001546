import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import Layout from '../components/layout';
import TechnologyLanding from '../components/TechnologyLanding';
import SEO from '../components/seo-component/seo';
//import LoginPage from '../pages/login';
import DialogPopupContext from '../utils/dialogpopup-context';

export default function TechnologyPage({location, pageContext, data }) {
  const [user, setUser] = useState({});
  const allFooterData = [pageContext.appData.footerMenu, pageContext.appData.siteFooter];
  const logoutUser = () => {
    clearSessionCookie();
    localStorage.setItem('user', JSON.stringify(false));
    window.location.reload();
  };
  const clearSessionCookie = () => {
    // the cookie to set is 'SESSION_USER'
    let cookieString = 'SESSION_USER=';
    const pathName = window.location.pathname.split('/')[1].trim();
    // we need to expire this cookie, so lets create a date object that points to yesterday
    const expiryDate = new Date();
    expiryDate.setTime(expiryDate.getTime() - 86400 * 1000);
    // change the cookie parameters and write the cookie back
    cookieString += `; max-age=0; path=/${pathName}`;
    cookieString += `; expires=${expiryDate.toUTCString()}`;
    document.cookie = cookieString;
  };
  const [open, setOpen] = useState(false);
  const openPopupClick = () => {
    setOpen(true);
  }
  const closePopupClick = () => {
    setOpen(false);
  }
  return (
    <>
      <DialogPopupContext.Provider value={{ status: open, openPopup: openPopupClick, closePopup: closePopupClick}}>
      <Layout
        partnerCode={pageContext.partnerCode}
        navigation={pageContext.appData}
        footer={allFooterData}
        styles={pageContext.theme}
        contactFormDetails={pageContext.appData.contactUsFormDetails}
        siteType={pageContext.appData.siteType}
        logout={logoutUser}
        metadata={pageContext.theme.partnerMetaData}
        searchEnabled={pageContext.appData.enableSearch}
        astEnabled={pageContext.appData.hasAgentSelectionToolAstRequired}
        basePath={pageContext.basePath}
        bucketName={pageContext?.bucketName}
        location={location}
      >
        <SEO
          data={data?.contentfulTechnologyPage?.seoEntry}
          siteurl={pageContext?.slug}
          bucketName={pageContext?.bucketName}
          basePath={pageContext.basePath}
          organizationName={pageContext?.appData?.organizationName}
          organizationLogo={pageContext?.appData?.organizationLogo?.file?.url}
          organizationLinkedinLink={pageContext?.appData?.organizationLinkedinLink}
          organizationFacebookLink={pageContext?.appData?.organizationFacebookLink}
          organizationYoutubeLink={pageContext?.appData?.organizationYoutubeLink}
          location={location}
          videoSEOSchema ={data?.contentfulTechnologyPage?.technologyPageIntroSection?.videoComponent?.videoComponent}

        />
        <TechnologyLanding
          data={data?.contentfulTechnologyPage}
          basePath={pageContext.basePath}
          images={data?.contentfulComponentBackgroundImages}
          location={location}
        />
      </Layout>
      </DialogPopupContext.Provider>
    </>
  );
}

export const query = graphql`
  query technologyQuery($contentful_id: String, $locale: String) {
    contentfulComponentBackgroundImages {
      gridBrightBlueBackgroundImage {
        file {
          url
        }
      }
      donutSliderBackgroundImage {
        file {
          url
        }
      }
      headerDonutImage {
        file {
          url
        }
      }
      hoverLeftArrowImage {
        file {
          url
        }
      }
      hoverRightArrowImage {
        file {
          url
        }
      }
      leftArrowImage {
        file {
          url
        }
      }
      rightArrowImage {
        file {
          url
        }
      }
      capsulesBackgroundImage{
        file{
          url
        }
      }
      headerDotMatrixImage{
        file{
          url
        }
      }
      dotMatrixBackgroundImage {
        file {
          url
        }
      }
      leftDonutBackgroundImage {
        file {
          url
        }
      }
      sys {
        contentType {
          sys {
            type
          }
        }
      }
    }
    contentfulTechnologyPage(contentful_id: { eq: $contentful_id }, node_locale: { eq: $locale }) {
      contentful_id
      headerType
      customerTestimonialAutoRotate
      customerTestimonialsAutoRotate
      homeCircularHeroImage {
        description
        file {
          url
        }
      }
      internalPageHeroImage {
        description
        file {
          url
        }
      }
      customerTestimonial {
        authorName
        authorDescription {
          raw
        }
        secondaryImage {
          description
          file {
            url
          }
        }
        authorDesignation
        authorImage {
          description
          title
          file {
            url
          }
        }
      }
      customerTestimonials {
        authorName
        authorDescription {
          raw
        }
        secondaryImage {
          description
          file {
            url
          }
        }
        authorDesignation
        authorImage {
          description
          title
          file {
            url
          }
        }
      }
      seoEntry {
        pageTitle
        metaDescription {
          metaDescription
        }
        metaKeywords
        metaRobotsTag
        ogType
        ogSiteName
        defaultOgLocale
        altimageTag
        ogImage {
          file {
            url
          }
        }
      }
      dotMatrixBackgroundImage {
        file {
          url
        }
      }
      heroImage {
        description
        file {
          url
        }
      }
      circularSectionTitle
      circularSectionDescription {
        raw
      }
      circularSectionBackgroundImage {
        file {
          url
        }
      }
      technologyPageIntroSection {
        header
        subHeader
        shortDescription {
          raw
        }
        isVideo
        image {
          description
          file {
            url
          }
        }
        videoComponent {
          videoComponent {
            dateOfPublishing(formatString: "YYYY-MM-DD")
            videoType
            videoId
            seoEntry {
              metaDescription {
                metaDescription
              }
            }
          }
        }
      }
      headerText
      shortDescription
      proudExecutionImage {
        description
        file {
          url
        }
      }
      ourProudExecutionTitle{
        raw
      }
      proudExecutionTabOrAccordion
      proudExecutionAccordionMenu{
        ...templateA
        ...templateB 
        
      }
      proudExecutionNavigationMenu {
        tabTitle
        description {
          raw
        }
        download
        pdfImage {
          description
          file {
            url
          }
        }
        uploadPdf {
          file {
            url
          }
        }
        image {
          description
          file {
            url
          }
        }
        authorName
        testimonialDescription {
          raw
        }
        designation
        ctaButtonLink {
          label
          link {
            ... on ContentfulInternalLink {
              slug
            }
            ... on ContentfulWebLink {
              url
            }
          }
        }
      }
      navigationTabRightArrowImage {
        file {
          url
        }
      }
      rightArrowImage {
        file {
          url
        }
        description
      }
      ancillaryTechnologySectionTitle
      ancillaryTechnologySectionRows {
        name
        image {
          description
          file {
            url
          }
        }
        description {
          raw
        }
      }
      technologyPageLetsTalkBackgroundImage {
        file {
          url
        }
      }
      technologyPageLetsTalkSectionTitle {
        raw
      }
      letsTalkButtonLink {
        label
        link {
          ... on ContentfulInternalLink {
            slug
          }
          ... on ContentfulWebLink {
            url
          }
        }
      }
      leftDonutBackgroundImage {
        file {
          url
        }
      }
      capsulesBackgroundImage {
        file {
          url
        }
      }
      capsulesLeftImage {
        file {
          url
        }
      }
      pardotForm {
        id
        contentful_id
        entryTitle
        header
        subHeader {
          raw
        }
        iFrameLink
        closeIcon {
          description
          file {
            url
          }
        }
      }
    }
  }

  fragment templateA on ContentfulGlobalServicesTemplateA {
    id
    contentful_id
    entryTitle
    serviceHeader
    accordionId
    description {
      raw
    }
    videoComponent {
      ...compVideo
    }
    serviceDescriptionSubService1 {
      ...headerDescription
    }
    serviceDescriptionSubService2 {
      ...headerDescription
    }
    serviceDescriptionSubService3 {
      ...headerDescription
    }
    serviceDescriptionSubService4 {
      ...headerDescription
    }
    servicesImageWithPdfDownloadLink {
      ...ImageWithPdfDownloadLink
    }
    testimonialSectionImage {
      file {
        url
      }
      title
      description
    }
    testimonialMessage {
      raw
    }
    name
    designation
    sys {
      contentType {
        sys {
          id
          type
        }
      }
    }
  }

  fragment templateB on ContentfulGlobalServicesTemplateB {
    id
    contentful_id
    entryTitle
    accordionId
    serviceImageWithPdfDownloadLink {
      downloadDocument {
        file {
          url
        }
      }
      downloadImage {
        file {
          url
        }
        description
      }
      downloadLink
    }
    videoComponent {
      ...compVideo
    }
    serviceHeader
    testimonialSectionImage {
      file {
        url
      }
      title
      description
    }
    testimonialMessage {
      raw
    }
    name
    designation
    image {
      file {
        url
      }
      description
    }
    description {
      raw
    }
    sys {
      contentType {
        sys {
          id
          type
        }
      }
    }
  }

  fragment compVideo on ContentfulCustomVideo {
    sys {
      contentType {
        sys {
          id
        }
      }
    }
    entryTitle
    videoComponent {
      entryTitle
      videoType
      videoId
      videoTitle
      dateOfPublishing
    }
  }

  fragment headerDescription on ContentfulComponentHeadingDescription {
    id
    contentful_id
    entryTitle
    headerText
    description {
      raw
    }
    sys {
      contentType {
        sys {
          id
          type
        }
      }
    }
  }

  fragment ImageWithPdfDownloadLink on ContentfulComponentImageWithPdfDownloadLink {
    id
    entryTitle
    contentful_id
    downloadImage {
      file {
        url
      }
      title
      description
    }
    downloadDocument {
      file {
        url
      }
      title
      description
    }
    downloadLink
    sys {
      contentType {
        sys {
          id
          type
        }
      }
    }
  }
`