import React from "react";
import Grid from '@material-ui/core/Grid';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import './OurInnovationTeamRightAlign.scss';
import clsx from 'clsx';
import { myStyles } from './style';

const OurInnovationTeamRightAlign = ({ name, description, image, data }) => {
    const classes = myStyles(data);

    return (
        <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className="right-align">
            <Grid item xl={6} lg={5} md={12} sm={12} xs={12} className='empty-div'>&nbsp;</Grid>
            <Grid item xl={6} lg={7} md={12} sm={12} xs={12}>
                <h3 locId="name">{name}</h3>
            </Grid>
            <Grid item xl={6} lg={5} md={12} sm={12} xs={12}>
                <div className='img-box' locId="image">
                    <img src={image?.file?.url+'?fm=webp&q=100'} alt={image?.description} />
                    <div className={clsx("dot-matrixs", classes["dot-matrix"])}></div> 
                </div>
            </Grid>
            <Grid item xl={6} lg={7} md={12} sm={12} xs={12} >
                <p className='description' locId="description">{documentToReactComponents(JSON.parse(description))}</p>
            </Grid>
        </Grid>
    );
};
export default OurInnovationTeamRightAlign;